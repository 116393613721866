<template>
  <transition
    appear
    appear-class="root-appear"
    appear-to-class="root-appear-to"
    appear-active-class="root-appear-active"
  >
    <div id="root_app">
      <template v-if="showStartScreen">
        <StartScreen />
      </template>

      <template v-else>
        <TopBar />

        <Menu />

        <div
          v-if="vappu"
          class="d-flex justify-content-center m-4"
          style="text-align: center;"
        >
          Vappuviikolla ravintoloiden aukioloajat saattavat näkyä väärin.
          Tarkista aukioloajat ravintoloiden omilta sivuilta klikkaamalla nimeä.
        </div>

        <transition name="bounce">
          <div
            v-if="showSundayBanner"
            class="d-flex justify-content-center m-4"
            style="text-align: center;"
          >
            <div class="alert alert-warning mb-0" role="alert">
              <strong>Jipii!</strong> Seuraavan viikon ruokalistat ovat jo
              saatavilla. Selaa niitä vaihtamalla päivää.
            </div>
          </div>
        </transition>

        <div ref="campus-slide-container" id="campus-slide-container">
          <transition name="campus-slide" mode="out-in">
            <template v-if="loading">
              <DotBouncer />
            </template>
            <template v-else-if="dayErrorMsg">
              <div class="error-container">
                Voi vitkale...
                <br />
                Valitun päivän ruokalistojen lataamisessa tapahtui jonkinlainen
                virhe. Yritä ladata sivu uudelleen.
              </div>
            </template>
            <template v-else>
              <Campus :key="currentDay" />
            </template>
          </transition>
        </div>
        <Footer />
      </template>
    </div>
  </transition>
</template>
<script>
import { TrackJS } from 'trackjs';

import StartScreen from './StartScreen.vue';
import Campus from './Campus.vue';
import TopBar from './TopBar.vue';
import DotBouncer from './DotBouncer.vue';

import { poistaSpinneri } from '../ui.js';

/* require('../../node_modules/bootstrap/dist/css/bootstrap.min.css'); */
require('../assets/bootstrap.min.css');
require('../assets/unisafka.css');

export default {
  name: 'App',
  components: {
    StartScreen,
    TopBar,
    Campus,
    Menu: () => import(/* webpackChunkName: 'menu' */ './Menu.vue'),
    Footer: () => import(/* webpackChunkName: 'footer' */ './Footer.vue'),
    DotBouncer,
  },
  data() {
    return {
      showFooter: false,
    };
  },
  computed: {
    showSundayBanner() {
      return this.$store.state.showSundayBanner;
    },
    vappu() {
      return this.$store.state.current.week === 18;
    },
    showStartScreen() {
      return !this.$store.state.prefs.campus;
    },
    loading() {
      return Object.keys(this.dataNyt).length === 0;
    },
    dayErrorMsg() {
      return this.dataNyt.error;
    },

    dataNyt() {
      return this.$store.getters.currentData;
    },

    currentDay() {
      return this.$store.state.current.dayName;
    },
  },
  created() {
    poistaSpinneri();
  },
  mounted() {
    console.log('MOUNTED');
    setTimeout(() => {
      this.showFooter = true;
    }, 500);

    TrackJS.addMetadata('vue', 'ok');
  },
};
</script>
<style scoped>
.error-container {
  text-align: center;
  position: absolute;
  top: 40%;
  width: 100%;
}

.root-appear {
  opacity: 0;
}
.root-appear-to {
  opacity: 1;
}
.root-appear-active {
  transition: opacity 1s;
}
.campus-slide-enter {
  opacity: 0;
}

.campus-slide-enter-to {
  opacity: 1;
}

.campus-slide-enter-active,
.campus-slide-leave-active {
  transition: all 0.4s ease-out;
}

.campus-slide-leave {
  transform: translateX(0);
}
.campus-slide-leave-to {
  opacity: 0;
}

#campus-slide-container {
  flex-grow: 1;
}

.show-footer-enter {
  opacity: 0;
  transform: translateY(150px);
}
.show-footer-enter-to {
  opacity: 1;
}
.show-footer-enter-active,
.show-footer-leave-active {
  transition: all 0.5s;
}

.show-footer-leave {
  opacity: 1;
}

.show-footer-leave-to {
  opacity: 0;
  transform: translateY(150px);
}

.footer-wrapper {
  transition: all 0.3s ease-out;
}
.bounce-enter-active {
  animation: bounce-in 1s;
}
.bounce-leave-active {
  animation: bounce-in 1s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
