<template>
  <div class="d-flex start-screen-container justify-content-center">
    <div class="p-2 align-self-center actual-container">
      <h3>Unisafka listaa TTY:n, TaY:n, TAYSin ja TAMKin ruokalistat</h3>
      <p class="mt-4">Aloita valitsemalla kampus!</p>
      <div class="buttons">
        <button
          v-for="campus in ['OMA', 'TTY', 'TAY', 'TAYS', 'TAMK']"
          :key="campus"
          @click="choose(campus)"
          class="btn btn-sm btn-success"
        >
          {{ campus }}
        </button>
      </div>
      <p style="margin-top: 0.75rem; font-size: 0.90rem; font-style: italic;">
        Valinta tallentuu automaattisesti seuraavaa vierailua varten
      </p>
      <p>
        Kampukset löytyvät myös suoraan osoitteista
        <a href="/tty/">unisafka.fi/tty</a>,
        <a href="/tay/">unisafka.fi/tay</a>,
        <a href="/tays/">unisafka.fi/tays</a> ja
        <a href="/tamk/">unisafka.fi/tamk</a>
      </p>
    </div>
  </div>
</template>
<script>
import { gaFirstTime } from '../ga';

export default {
  name: 'StartScreen',
  methods: {
    choose(campus) {
      this.$store.commit('CHOOSE_CAMPUS', campus);
      gaFirstTime();
    },
  },
};
</script>
<style scoped>
.start-screen-container {
  text-align: center;
  flex: 1;
}

.actual-container {
  margin-top: -5rem;
}

.buttons .btn {
  margin: 0.25rem;
}
</style>
