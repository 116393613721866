import Vue from 'vue';
import Vuex from 'vuex';
import PersistedState from 'vuex-persistedstate';

import * as actions from './actions.js';
import * as getters from './getters.js';
import * as mutations from './mutations.js';

Vue.use(Vuex);

Date.prototype.getWeek = function() {
  const onejan = new Date(this.getFullYear(), 0, 1);
  const lasutesti = new Date();
  if (lasutesti.getDay() === 6 || lasutesti.getDay() === 0) {
    return (
      Math.ceil(((this - onejan) / 86400000 + onejan.getDay() + 1) / 7) - 1
    );
  }
  return Math.ceil(((this - onejan) / 86400000 + onejan.getDay() + 1) / 7);
};

const days = ['su', 'ma', 'ti', 'ke', 'to', 'pe', 'la'];
const w = window,
  d = document,
  e = d.documentElement,
  g = d.getElementsByTagName('body')[0],
  WIDTH = w.innerWidth || e.clientWidth || g.clientWidth;

export const FILTER_TYPES = {
  HIGH: 'high',
  ONLY: 'only',
  HIDE: 'hide',
};

export default new Vuex.Store({
  state: {
    mobile: WIDTH < 990,
    showSundayBanner: false,
    days,
    campuses: ['OMA', 'TTY', 'TAY', 'TAYS', 'TAMK'],
    current: {
      dayName: null,
      week: null,
      year: null,
    },

    diets: ['G', 'L', 'M', 'KASV', 'VEG'],

    dietFilterTypes: [
      { name: FILTER_TYPES.HIGH, text: 'Korosta' },
      { name: FILTER_TYPES.ONLY, text: 'Näytä vain' },
      { name: FILTER_TYPES.HIDE, text: 'Piilota' },
    ],

    prefs: {
      campus: null,
      orders: {},
      hiddens: {},
      hideWholeRes: false,
      dietFilters: [],
      dietFilterType: FILTER_TYPES.HIGH,
      dietFilterLogicalAnd: true,
      showGroups: false,
      showDiets: false,
      showPrices: false,
      hideCustomCampusMsg: false,
    },

    jsonData: {
      version: 1,
      week: 1,
      year: 2019,
      ma: {},
      ti: {},
      ke: {},
      to: {},
      pe: {},
      la: {},
      su: {},
    },
  },

  mutations,
  actions,
  getters,

  plugins: [
    PersistedState({
      paths: ['prefs', 'jsonData'],
    }),
  ],
});
