<template>
  <div class="col-md-4 restaurant-outer-container">
    <div class="d-flex justify-content-between name-hours-row">
      <div class="align-self-end restaurant-name">
        <a
          :class="{ hidden: hidden }"
          :href="restaurant.rurli"
          class=""
          target="_BLANK"
          >{{ restaurant.restaurant }}</a
        >
      </div>

      <div v-if="!hidden" class="hours-outer">
        <div class="eating-hours">
          <span class="restaurant">{{ restaurant.eating_hours }}</span>
        </div>
        <div class="open-hours">
          <template v-if="restaurant.open_hours">{{
            restaurant.open_hours
          }}</template>
        </div>
      </div>
    </div>

    <template v-if="!hidden">
      <template v-if="restaurant.open_today">
        <template v-if="restaurant.lisainfo">
          <div class="lisainfoboksi_yla">
            {{ restaurant.lisainfo }}
          </div>
        </template>
        <div class="meal_box">
          <!-- eslint-disable-next-line -->
          <Meal v-for="(meal, index) in meals" :key="`meal-${index}`" :meal="meal" :prefs="prefs" />
        </div>
      </template>

      <template v-else>
        <div class="mealit">
          <div class="">
            <template v-if="restaurant.lisainfo">
              {{ restaurant.lisainfo }}
            </template>
            <template v-else
              >Ravintola kiinni tänään</template
            >
          </div>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import Meal from './Meal.vue';
import { FILTER_TYPES } from '../store';

export default {
  name: 'Restaurant',
  props: ['restaurant'],
  components: {
    Meal,
  },
  computed: {
    meals() {
      const filters = this.prefs.dietFilters;
      const AND = this.prefs.dietFilterLogicalAnd;

      return this.restaurant.meals
        .filter(meal => meal.mo.length > 0)
        .map(meal => ({
          ...meal,
          high: false,
        }))
        .filter(meal => {
          if (meal.hmd.length > 0 && filters.length > 0) {
            const hmd = meal.hmd.split(' ');
            const filtersNotFound = filters.filter(f => !hmd.includes(f));

            const unsatisfiedFiltersFound = AND
              ? filtersNotFound.length !== 0
              : filtersNotFound.length === filters.length;

            switch (this.prefs.dietFilterType) {
              case FILTER_TYPES.HIGH:
                meal.high = !unsatisfiedFiltersFound;
                return true;

              case FILTER_TYPES.ONLY:
                return !unsatisfiedFiltersFound;

              case FILTER_TYPES.HIDE:
                return unsatisfiedFiltersFound;

              default:
                return true;
            }
          }
          return true;
        });
    },
    prefs() {
      return this.$store.state.prefs;
    },
    hidden() {
      return this.$store.getters.isResHidden(this.restaurant.res);
    },
  },
};
</script>
<style scoped>
.hidden {
  text-decoration: line-through;
  font-style: italic;
}

.restaurant-outer-container {
  margin-bottom: 2rem;
  transition: all 1s;
}

.name-hours-row {
  margin-bottom: 0.25rem;
}

.restaurant-name {
  text-decoration: underline;
  font-weight: bold;
  font-size: 1.2rem;
}

.hours-outer {
  font-size: 0.8rem;
}
</style>
