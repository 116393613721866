import Vue from 'vue';
import { TrackJS } from 'trackjs';

import store from './store/index.js';
import App from './comp/App.vue';
import { initFonts } from './utils.js';
import { initSW } from './sw.js';


if (window.location.hostname !== 'localhost') {
  window.TrackJS &&
    TrackJS.install({
      token: '04ec886748e4440fb23bbb6d6a014583',
      application: 'unisafka-trackjs',
      console: { enabled: false },
    });
}

// eslint-disable-next-line
console.log(BUNDLEMODE);

initFonts();

const refresh = () => {
  store.commit('INVALIDATE_DATA');
  store.dispatch('GET_CURRENT_DAY');
  store.dispatch('FLUSH_CACHE');
  setTimeout(() => store.dispatch('GET_ALL_DAYS'), 150);
  setTimeout(() => store.dispatch('CHECK_SUNDAY'), 1000);
};

document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    refresh();
  }
});

const url = window.location.href.split('/');
['tty', 'tay', 'tays', 'tamk'].forEach(k => {
  if (url.includes(k)) {
    store.commit('CHOOSE_CAMPUS', k);
  }
});

refresh();

new Vue({
  el: '#APP',
  render: h => h(App),
  store: store,
});

import(/* webpackChunkName: 'doorbell' */ './doorbell.js').then(module => {
  const def = module.default;
  def.initDoorbell(store.state.mobile);
});

initSW();
