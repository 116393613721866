export const eiExternal = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="20px" height="20px" style="fill: #337ab7;">
<path d="M38.288 10.297l1.414 1.415-14.99 14.99-1.414-1.414z"/>
<path d="M40 20h-2v-8h-8v-2h10z"/>
<path d="M35 38H15c-1.7 0-3-1.3-3-3V15c0-1.7 1.3-3 3-3h11v2H15c-.6 0-1 .4-1 1v20c0 .6.4 1 1 1h20c.6 0 1-.4 1-1V24h2v11c0 1.7-1.3 3-3 3z"/>
</svg>`;

export const checkF = `
<svg version="1.1" baseProfile="basic"
xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px"
viewBox="0 0 32 32" xml:space="preserve">
<path transform="matrix(0.032,0,0,0.032,0,0)" stroke="none" style="fill:#000000" d="M 455 1000 L 295 1000 L 295 918 L 455 918 L 455 1000 z M 295 81 L 455 81 L 455 0 L 295 0 L 295 81 z M 542 81 L 698 81 L 698 0 L 542 0 L 542 81 z M 896 557 L 896 689 L 977 689 L 977 557 L 896 557 z M 0 557 L 0 689 L 81 689 L 81 557 L 0 557 z M 81 470 L 81 314 L 0 314 L 0 470 L 81 470 z M 208 911 C 145 893 96 841 85 776 L 2 776 C 15 887 99 975 208 995 L 208 911 z M 84 226 C 95 160 144 106 208 88 L 208 4 C 98 24 13 114 1 226 L 84 226 z M 892 776 C 882 836 840 884 785 906 L 785 992 C 886 966 963 881 975 776 L 892 776 z M 896 314 L 896 470 L 977 470 L 977 314 L 896 314 z M 785 93 C 841 115 883 165 893 226 L 976 226 C 965 120 887 33 785 7 L 785 93 z M 698 918 L 542 918 L 542 1000 L 698 1000 L 698 918 z"/>
</svg>`;
export const icoJarjesta = `
<svg version="1.1" baseProfile="basic"
xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="15px" height="15px"
viewBox="0 0 32 32" xml:space="preserve">
<path transform="matrix(0.032,0,0,0.032,0,0)" stroke="none" style="fill:#000000" d="M 1000 360 L 1000 0 L 999 0 L 748 0 L 748 0 L 639 0 L 806 167 L 499 473 L 193 167 L 360 0 L 0 0 L 0 360 L 167 193 L 473 499 L 167 806 L 0 639 L 0 1000 L 360 1000 L 193 832 L 499 526 L 806 832 L 639 1000 L 999 1000 L 1000 806 L 1000 748 L 1000 748 L 1000 639 L 832 806 L 526 499 L 832 193 z"/>
</svg>`;

export const iconSettings = `
<svg version="1.1" baseProfile="basic"
   xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32px" height="32px"
   viewBox="0 0 32 32" xml:space="preserve"
   style="height:25px;margin-bottom:-6px">
  <path transform="matrix(0.032,0,0,0.032,0,2)" stroke="none" style="fill:#EEEEEE" d="M 486 620 C 490 596 490 572 486 549 L 564 517 L 530 433 L 451 465 C 438 446 421 429 401 416 L 434 338 L 350 303 L 317 381 C 294 377 270 377 246 381 L 214 303 L 130 338 L 162 416 C 143 429 126 446 112 466 L 34 434 L 0 517 L 78 549 C 74 573 74 597 78 620 L 0 652 L 35 736 L 113 704 C 126 723 143 740 163 754 L 130 832 L 215 866 L 247 788 C 270 792 294 792 318 788 L 350 866 L 434 831 L 402 753 C 421 740 438 723 452 703 L 530 736 L 565 652 L 486 620 z M 369 621 C 354 657 319 679 282 679 C 270 679 258 677 246 672 C 198 652 175 597 195 549 C 210 512 245 490 282 490 C 294 490 306 493 318 498 C 366 517 389 573 369 621 z M 920 312 C 924 289 923 265 918 242 L 996 207 L 959 125 L 881 159 C 867 140 850 124 830 111 L 860 32 L 775 0 L 745 78 C 721 75 697 76 674 80 L 639 3 L 556 40 L 591 117 C 572 132 555 149 542 169 L 463 139 L 431 223 L 510 253 C 507 277 507 301 512 324 L 435 358 L 472 441 L 549 407 C 563 426 581 442 601 455 L 571 534 L 655 566 L 686 487 C 709 491 733 490 756 485 L 791 562 L 874 525 L 839 448 C 858 434 875 417 888 397 L 967 427 L 1000 342 L 920 312 z M 804 316 C 789 353 755 376 718 377 C 706 377 693 375 682 371 C 633 352 608 298 627 249 C 641 213 676 190 713 189 C 725 188 737 190 749 195 C 798 213 822 268 804 316 z"/>
</svg>`;

export const ok = `
<svg version="1.1" baseProfile="basic"
   xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32px" height="32px"
   viewBox="0 0 32 32" xml:space="preserve"
   style="width:17px;">
  <path transform="matrix(0.032,0,0,0.032,0,4)" stroke="none" style="fill:#008000" d="M 996 4 C 992 0 986 0 982 3 L 314 510 L 17 296 C 13 293 7 294 4 297 C 0 301 0 306 2 310 L 302 745 C 304 747 307 749 310 749 C 310 749 310 749 311 749 C 313 749 316 748 318 746 L 996 18 C 999 14 1000 8 996 4 z"/>
</svg>`;

export const up = `
<svg version="1.1" baseProfile="basic"
   xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32px" height="32px"
   viewBox="0 0 32 32" xml:space="preserve"
    style="width:16px;">
  <path transform="matrix(0.032,0,0,0.032,0,2)" stroke="none" style="fill:#008000" d="M 1000 827 L 0 827 L 499 0"/>
</svg>`;

export const down = `
<svg version="1.1" baseProfile="basic"
   xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32px" height="32px"
   viewBox="0 0 32 32" xml:space="preserve"
    style="width:16px;">
  <path transform="matrix(0.032,0,0,0.032,0,2)" stroke="none" style="fill:#008000" d="M 0 0 L 1000 0 L 500 827"/>
</svg>`;
