/* global ga */
import store from './store/index';

const gaFound = () => window.ga && ga.create;

export function gaVueFire() {
  if (gaFound()) {
    ga('send', 'event', {
      eventCategory: 'piirto',
      eventAction: 'kampus',
      eventLabel: store.state.prefs.campus,
    });
  }
  gaAppVersion();
  gaBundleMode();
}

export function gaFirstTime() {
  if (gaFound()) {
    ga('send', 'event', {
      eventCategory: 'piirto',
      eventAction: 'ekakerta',
      eventLabel: store.state.prefs.campus,
    });
  }
}

export function gaOnError(funk, msg) {
  if (gaFound()) {
    ga('send', 'event', {
      eventCategory: 'error',
      eventAction: funk,
      eventLabel: msg,
    });
  }
}

export function gaButton(btn) {
  if (gaFound()) {
    ga('send', 'event', {
      eventCategory: 'piirto',
      eventAction: 'toiminnot',
      eventLabel: btn,
    });
  }
}

export function gaCache(truth) {
  if (gaFound()) {
    ga('send', 'event', {
      eventCategory: 'stats',
      eventAction: 'cacheHit',
      eventLabel: truth,
    });
  }
}

export function gaCacheBusted(newBit) {
  if (gaFound()) {
    ga('send', 'event', {
      eventCategory: 'stats',
      eventAction: 'cacheBusted',
      eventLabel: `cb-${newBit}`,
    });
  }
}

export function gaFetchAnother(message) {
  if (gaFound()) {
    ga('send', 'event', {
      eventCategory: 'stats',
      eventAction: 'upgradeVersion',
      eventLabel: message,
    });
  }
}

export function gaAppVersion() {
  if (gaFound()) {
    ga('send', 'event', {
      eventCategory: 'stats',
      eventAction: 'version',
      eventLabel: `${store.state.current.week}::${
        store.state.jsonData.version
      }`,
    });
  }
}

function gaBundleMode() {
  if (gaFound()) {
    ga('send', 'event', {
      eventCategory: 'stats',
      eventAction: 'bundle',
      // eslint-disable-next-line
      eventLabel: BUNDLEMODE,
    });
  }
}

export function gaPerfNow(now) {
  if (gaFound()) {
    ga('send', {
      hitType: 'timing',
      timingCategory: 'stats',
      timingVar: 'vue-created',
      timingValue: now,
    });
  }
}

export function gaPiirto(kampus) {
  if (gaFound()) {
    ga('send', 'event', {
      eventCategory: 'piirto',
      eventAction: kampus,
      eventLabel: kampus,
    });
  } else {
    return true;
  }
}
