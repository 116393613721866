import Vue from 'vue';

const toggleInArray = (array, thing) => {
  if (array.indexOf(thing) === -1) {
    array.push(thing);
  } else {
    array = array.filter(e => e !== thing);
  }
  return array;
};
export const CHOOSE_CAMPUS = (state, campus) => {
  state.prefs.campus = campus.toLowerCase();
};

export const CHOOSE_DAY = (state, day) => {
  state.current.dayName = day;
};

export const CHOOSE_DIET = (state, diet) => {
  state.prefs.dietFilters = toggleInArray(
    state.prefs.dietFilters.slice(),
    diet
  );
};

export const CHOOSE_DIET_FILTER_TYPE = (state, type) => {
  state.prefs.dietFilterType = type;
};

export const CHOOSE_DIET_FILTER_LOGICAL_AND = (state, type) => {
  state.prefs.dietFilterLogicalAnd = type;
};

export const CHOOSE_SETTING = (state, setting) => {
  state.prefs[setting] = !state.prefs[setting];
};

export const SET_NEW_ORDER = (state, newOrder) => {
  Vue.set(state.prefs.orders, state.prefs.campus, newOrder);
};

export const SET_DAY_TO_ERR = (state, day) => {
  state.jsonData[day].error = true;
};

export const TOGGLE_RES_VISIBILITY = (state, res) => {
  const campus = state.prefs.campus;
  if (!state.prefs.hiddens[campus]) {
    Vue.set(state.prefs.hiddens, campus, []);
  }
  state.prefs.hiddens[campus] = toggleInArray(state.prefs.hiddens[campus], res);
};

export const SET_NEW_HIDDENS_FOR_CAMPUS = (state, hiddens) => {
  if (!state.prefs.hiddens[state.prefs.campus]) {
    Vue.set(state.prefs.hiddens, state.prefs.campus, []);
  }
  state.prefs.hiddens[state.prefs.campus] = hiddens;
};

export const BUST_CACHE_VERSION = (state, newVersion) => {
  console.log('BUST_CACHE_VERSION ', newVersion);
  state.jsonData.version = parseInt(newVersion);
};

export const INVALIDATE_DATA = state => {
  const d = new Date();
  console.log('INVALIDATE_DATA ' + d);
  state.current.year = d.getFullYear();
  state.current.week = d.getWeek();
  state.current.dayName = state.days[d.getDay()];

  console.log(state.currentWeek);
  if (state.jsonData.week !== state.current.week) {
    console.log('INVALIDATING, NOW IS A NEW WEEK');
    state.jsonData.version = 1;
    state.days.forEach(day => {
      Vue.set(state.jsonData, day, {});
    });
  }
};

export const SET_HIDE_WHOLE_RES = (state, val) => {
  state.prefs.hideWholeRes = val;
};

export const HIDE_CUSTOM_CAMPUS_INFO_MSG = state => {
  state.prefs.hideCustomCampusMsg = true;
};
