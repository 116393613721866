export function initSW() {
  setTimeout(() => {
    if ('serviceWorker' in navigator) {
      console.log('SW:CLIENT: in progress');
      navigator.serviceWorker.register('/service-worker.js').then(
        function() {
          console.log('SW:CLIENT: reg complete');
        },
        function() {
          console.log('SW:CLIENT: reg failure');
        }
      );
    } else {
      console.log('SW:CLIENT: not supported');
    }
  }, 2000);
}
