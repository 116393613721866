<template>
  <transition
    appear
    appear-class="top-appear"
    appear-to-class="top-appear-to"
    appear-active-class="top-appear-active"
  >
    <div class="container first-container">
      <div class="row d-flex title-row">
        <div class="d-flex align-items-center" style="min-width:45px;">
          <img src="/static/logo.png" />
        </div>
        <div class="col-auto">
          <div class="title">
            Unisafka
          </div>
          <div id="title-day">
            {{ dayText }}
          </div>
        </div>

        <div id="top-links" class="ml-auto">
          <a href="/tty/">/tty</a> <a href="/tay/">/tay</a>
          <a href="/tays/">/tays</a> <a href="/tamk/">/tamk</a>
          <br />
          <a href="https://nyssetutka.fi" target="_BLANK">Nyssetutka.fi</a>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'TopBar',
  computed: {
    dayText() {
      return (
        this.$store.state.jsonData[this.$store.state.current.dayName].dteksti ||
        ''
      );
    },
  },
};
</script>
<style scoped>
.title-row {
  margin-top: 1rem;
  padding-left: 1rem;
  justify-content: flex-start !important;
}

@media screen and (max-width: 999px) {
  .title-row {
    justify-content: center !important;
    padding-left: 0;
  }
  #top-links {
    display: none;
  }
}

.title {
  font-size: 2rem;
}

.title-row img {
  max-width: 45px;
}

#title-day {
  margin-top: -0.75rem;
  margin-left: 0.3rem;
}

#top-links a {
  margin-left: 0.75rem;
}

.top-appear {
  opacity: 0;
}

.top-appear-to {
  opacity: 1;
}

.top-appear-active {
  transition: all 2s;
}
</style>
