<template>
  <div class="meal" :class="{ 'highlight-meal': meal.high }">
    <div class="d-flex justify-content-between">
      <div class="meal-group" v-if="prefs.showGroups">
        {{ meal.kok }}
      </div>

      <div class="ml-auto meal-price" v-if="prefs.showPrices">
        {{ meal.price }}
      </div>
    </div>

    <div class="d-flex" :class="{ 'pl-2': prefs.showGroups }">
      <div class="mealit">
        <template v-for="part in meal.mo">
          <div :key="part.mpn" class="meal-name">
            {{ part.mpn }}
          </div>
          <div :key="part.mpn + 'd'" v-if="prefs.showDiets" class="meal-diet">
            {{ part.mpd }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { ok } from '../icons.js';
import { FILTER_TYPES } from '../store/index';

export default {
  name: 'Meal',
  props: ['meal', 'prefs'],
  data() {
    return {
      iconDietOk: ok,
    };
  },
  computed: {
    dietFiltersInUse() {
      return (
        !(this.prefs.dietFilters.length === 0) &&
        this.prefs.dietFilterType === FILTER_TYPES.HIGH
      );
    },
  },
};
</script>
<style scoped>
.meal {
  border-bottom: 1px solid #d1d0d0;
  padding: 0.5rem;
}

.meal:last-child {
  border-bottom: none;
}

.highlight-meal {
  background: #90ee906e;
}

.meal-name {
  font-size: 0.95rem;
}

.meal-diet {
  color: #495057;
  margin-left: 0.5rem;
  font-size: 0.85rem;
}

.meal-price {
  color: #495057;
  text-align: right;
  font-size: 0.85rem;
}

.meal-group {
  color: #495057;
  text-transform: uppercase;
  font-size: 0.85rem;
}
</style>
