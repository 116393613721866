import Vue from 'vue';
import { gaCacheBusted, gaAppVersion } from '../ga.js';

export const GET_CURRENT_DAY = ({ state, commit }) => {
  GET_SINGLE_DAY({ state, commit }, state.current.dayName);
};

export const GET_ALL_DAYS = ({ dispatch }, skipSunday) => {
  console.log('skipSynday', skipSunday);
  ['ma', 'ti', 'ke', 'to', 'pe', 'la', 'su'].forEach((day, index) => {
    if (day === 'su' && skipSunday) return;
    setTimeout(() => {
      dispatch('GET_SINGLE_DAY', day);
    }, 25 * index);
  });
};

const customCampusData = allData => {
  return {
    ...allData['restaurants_tty'],
    ...allData['restaurants_tay'],
    ...allData['restaurants_tays'],
    ...allData['restaurants_tamk'],
  };
};

export const GET_SINGLE_DAY = ({ state, commit }, day) => {
  const week = state.current.week;
  const version = state.jsonData.version;
  const dataURL = `/static/json/2019/${week}/${version}/${day}.json`;

  const currentData = state.jsonData[day];
  if (currentData.week === week && currentData.version === version) {
    console.log('FETCHING NOT NEEDED; DATA IN CACHE ' + dataURL);
    return Promise.resolve();
  }

  console.log('FETCHING NEEDED -> ' + dataURL);
  return fetch(dataURL)
    .then(res => res.json())
    .then(resData => {
      Object.keys(resData).forEach(resDataKey => {
        if (resDataKey.indexOf('restaurants_') === 0) {
          Object.keys(resData[resDataKey]).forEach(resKey => {
            resData[resDataKey][resKey]['res'] = resKey;
          });
        }
      });

      if (version === state.jsonData.version) {
        state.jsonData.week = state.current.week;
        state.jsonData.year = state.current.year;
        resData.week = week;
        resData.version = version;
        resData['restaurants_oma'] = customCampusData(resData);
        Vue.set(state.jsonData, day, resData);
      } else {
        console.log('CACHE WAS BUSTED DURING GET ALL DAYS');
      }
    })
    .catch(err => { // eslint-disable-line
      commit('SET_DAY_TO_ERR', day);
    });
};

export const CHOOSE_DAY = ({ state, commit, dispatch }, day) => {
  commit('CHOOSE_DAY', day);

  if (Object.keys(state.jsonData[day]).length < 2) {
    dispatch('GET_SINGLE_DAY', day);
  }
};

const fetchVersion = (week, year) => {
  const remote = `/static/json/${year}/${week}/v.json`;
  return fetch(remote, {
    headers: {
      'cache-control': 'no-cache',
      pragma: 'no-cache',
    },
  }).then(r => r.json());
};

export const FLUSH_CACHE = ({ state, dispatch, commit }) => {
  console.log('FLUSH CACHE STARTS NOW');

  const d = new Date();
  const week = d.getWeek();
  const year = d.getFullYear();

  fetchVersion(week, year)
    .then(cache => {
      const newVersion = cache['v'];
      const oldVersion = state.jsonData.version;
      console.log(`newVersion :: ${oldVersion} vs ${newVersion}`);
      if (parseInt(newVersion) > parseInt(oldVersion)) {
        commit('BUST_CACHE_VERSION', newVersion);
        dispatch('GET_ALL_DAYS');

        gaCacheBusted(`${year}-${week}-${newVersion}`);
        gaAppVersion(`${year}-${week}`, newVersion);
      }
    })
    .catch(err => {
      console.log('CATCH::flushCache');
      console.log(err);
    });
};

export const CHECK_SUNDAY = ({ state, dispatch }) => {
  const dnow = new Date();
  if (dnow.getDay() === 0) {
    fetchVersion(dnow.getWeek() + 1, dnow.getFullYear())
      .then(v => {
        state.jsonData.version = parseInt(v['v']);
        state.current.week = dnow.getWeek() + 1;
        state.days.forEach(day => {
          if (day === 'su') return;
          Vue.set(state.jsonData, day, {});
        });
        dispatch('GET_ALL_DAYS', true);
        state.showSundayBanner = true;
      })
      .catch(err => {
        console.log('TMRS V NOT AVAILABLE', err);
      });
  }
};
